import React, { Fragment, useState } from "react";
import { Table } from "react-bootstrap";
import {
  FiChevronDown,
  FiChevronLeft,
  FiChevronRight,
  FiChevronUp,
} from "react-icons/fi";

import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  ColumnFiltersState,
} from "@tanstack/react-table";
import GlobalFilter from "./GlobalFilter";
import CustomPagination from "./CustomPagination";
import SimpleBar from "simplebar-react";
import Filter from "./Filter";

const TanstackTable = ({
  data,
  columns,
  filter = false,
  sortedBy,
  filterPlaceholder,
}) => {
  const [filtering, setFiltering] = useState("");
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = React.useState([
    {
      id: "booking_date",
      desc: true,
    },
  ]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const table = useReactTable({
    data,
    columns,

    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),

    state: {
      globalFilter: filtering,
      rowSelection: rowSelection,
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    enableRowSelection: true,
    onGlobalFilterChange: setFiltering,
    onPaginationChange: setPagination,

    debugTable: false,
  });

  return (
    <Fragment>
      {filter && (
        <GlobalFilter
          filtering={filtering}
          setFiltering={setFiltering}
          placeholder={filterPlaceholder}
          table={table}
        />
      )}
      <SimpleBar forceVisible="x">
        <div className="table-responsive">
          <Table
            size="lg"
            className="text-nowrap tanstack_table"
            style={{ width: "100%" }}
          >
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      colSpan={header.colSpan}
                    >
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? "sorting d-flex gap-2 align-items-center"
                                : "d-flex",
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: <FiChevronDown size={16} />,
                              desc: <FiChevronUp size={16} />,
                            }[header.column.getIsSorted()] ?? null}
                          </div>
                        </>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </SimpleBar>

      <hr />
      <CustomPagination table={table} />
    </Fragment>
  );
};

export default TanstackTable;
