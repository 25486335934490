"use client";

import React from "react";
import { Row, Col, Button, ListGroup } from "react-bootstrap";

const CustomPagination = ({ table }) => {
  const pageCount = table.getPageCount();
  console.log(pageCount, typeof pageCount);

  const pageIndex = table.options.state.pagination.pageIndex;

  return (
    <Row className="justify-content-between align-align-items-center ">
      <Col md={5} sm={12}>
        <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </Col>
      <Col md={7} sm={12}>
        <div className="">
          <nav>
            <ListGroup
              as="ul"
              bsPrefix="pagination"
              className="justify-content-end mb-0"
            >
              <ListGroup.Item
                as="li"
                bsPrefix="page-item paginate_button page-item previous"
                className={!table.getCanPreviousPage() ? "disabled" : ""}
              >
                <button
                  disabled={!table.getCanPreviousPage()}
                  onClick={() => table.previousPage()}
                  className="page-link"
                >
                  Previous
                </button>
              </ListGroup.Item>
              {/* {Array.from(Array(pageCount).keys()).map((page, index) => (
                <ListGroup.Item
                  as="li"
                  bsPrefix="page-item paginate_button"
                  key={index}
                  className={pageIndex === page ? "active" : ""}
                >
                  <button
                    onClick={() => table.setPageIndex(page)}
                    className="page-link"
                  >
                    {page + 1}
                  </button>
                </ListGroup.Item>
              ))} */}
              <ListGroup.Item
                as="li"
                bsPrefix="page-item paginate_button"
                className={!table.getCanNextPage() ? "disabled" : ""}
              >
                <button
                  disabled={!table.getCanNextPage()}
                  onClick={() => table.nextPage()}
                  className="page-link"
                >
                  Next
                </button>
              </ListGroup.Item>
            </ListGroup>
          </nav>
        </div>
      </Col>
    </Row>
  );
};

export default CustomPagination;
