export function capitalizeWords(str) {
  return str.replace(/\b\w/g, function (match) {
    return match.toUpperCase();
  });
}

//Search Handler
export function searchWithFilter(items, searchParams) {}

export function filterDataByDateRange(data, searchParams) {
  // Check if any search parameters are provided
  const hasSearchParams =
    searchParams.query ||
    searchParams.startDate ||
    searchParams.endDate ||
    searchParams.staff;

  if (!hasSearchParams) {
    // If no search parameters are provided, set the filtered data to the complete data
    return data;
  }

  // Implement your search logic here
  const filterData = data.filter((booking) => {
    // Check if the query matches the booking's name or number
    const queryMatch =
      booking.customer_name
        .toLowerCase()
        .includes(searchParams.query.toLowerCase()) ||
      booking.product_name
        .toLowerCase()
        .includes(searchParams.query.toLowerCase());

    // Check if the booking date is within the specified range
    const startDateMatch =
      !searchParams.startDate ||
      new Date(booking.start_date) >= new Date(searchParams.startDate);
    const endDateMatch =
      !searchParams.endDate ||
      new Date(booking.start_date) <= new Date(searchParams.endDate);

    // Check if the staff matches
    const staffMatch =
      !searchParams.staff || booking.staff_name === searchParams.staff;

    return queryMatch && startDateMatch && endDateMatch && staffMatch;
  });

  // const filteredData = data.filter((item) => {
  //   const bookingDate = new Date(item.start_date);

  //   if (endDate) {
  //     // Check if the booking date is within the specified range
  //     return (
  //       bookingDate >= new Date(startDate) && bookingDate <= new Date(endDate)
  //     );
  //   } else {
  //     // If endDate is not provided, only check against startDate
  //     return bookingDate >= new Date(startDate);
  //   }
  // });

  return filterData;
}
