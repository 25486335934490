import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  Box,
  useTheme,
  Button,
  Tabs,
  Tab,
  TextField,
  IconButton,
  Typography,
  useMediaQuery,
  FormControl,
  Grid,
  MenuItem,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { axiosInstance } from "../base/api/axios.util";
import { URLConstants } from "../base/api/url.constants";
import Loader from "react-loader";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { ClientContext } from "../base/contexts/UserContext";
import Helmet from "components/Helmet/Helmet";
import { useClient } from "../base/hooks/useClient";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import Wrapper from "components/UI/Wrapper";
import moment from "moment/moment";
import { BsDownload } from "react-icons/bs";
import TanstackTable from "components/Table/TanstackTable";
import Flatpickr from "react-flatpickr";
import CustomDatePicker from "components/CustomDatePicker";
import { Form } from "react-bootstrap";
import { searchWithFilter, filterDataByDateRange } from "helper/utils";
import SearchBar from "components/SearchBar";
import { DateRangePicker } from "react-date-range";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

// Custom Tab
function CustomTabPanel(props) {
  const client = useClient();
  const navigate = useNavigate();

  useEffect(() => {
    if (client?.client?.role == undefined) {
      navigate("/");
    } else {
      console.log("CLeint else condiiotn", client?.client?.role);
    }
  }, []);

  console.log("Client in bookings testing useCLient", client);

  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Bookings = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { client, setClient } = useContext(ClientContext);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // values to be sent to the backend
  const [value, setValue] = React.useState(1);
  const [loaded, setLoaded] = useState(true);
  const [bookings, setBookings] = useState([]);
  // const [manualBookings, setManualBookings] = useState([]);
  const [reloadPage, setReloadPage] = useState(false);

  // Search States
  const [staff, setStaff] = useState([]);

  const [searchParams, setSearchParams] = useState({
    query: "",
    startDate: "",
    endDate: "",
    staff: "",
  });

  const {
    data: manualBookings,
    isLoading: isMBLoading,
    error,
  } = useQuery({
    queryKey: ["manualBooking"],
    queryFn: () => axiosInstance.get(URLConstants.manualbookings()),
  });

  console.log("Dataaaa", manualBookings);

  // Online Booking Fetching
  const { data: onlineBooking, isLoading: isOBLoading } = useQuery({
    queryKey: ["onlineBooking"],
    queryFn: () => axiosInstance.get(URLConstants.bookings()),
  });

  // Staff List Search
  const { data: staffList, isLoading: isStaffLoading } = useQuery({
    queryKey: ["staffList"],
    queryFn: () => axiosInstance.get(URLConstants.staff()),
  });

  function deleteBooking(bookingId) {
    setLoaded(false);
    axiosInstance
      .delete(URLConstants.modifyManualBookings(bookingId))
      .then((response) => {
        setLoaded(true);
        setReloadPage(!reloadPage);
      })
      .catch((err) => {
        setLoaded(true);
        console.log(err);
      });
  }

  //Tabs Handle Change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //Manual Booking Status
  const renderStatus = (item) => {
    switch (item) {
      case 1:
        return (
          <Button
            variant="Contained"
            sx={{
              backgroundColor: "green",
              color: theme.palette.neutral.white,
            }}
          >
            Confirmed
          </Button>
        );
      case 2:
        return (
          <Button
            variant="Contained"
            sx={{ backgroundColor: "red", color: theme.palette.neutral.white }}
          >
            Cancelled
          </Button>
        );
      case 3:
        return (
          <Button
            variant="Contained"
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.neutral.white,
            }}
          >
            Enquiry
          </Button>
        );
      case 4:
        return (
          <Button
            variant="Contained"
            sx={{
              backgroundColor: theme.palette.secondary.purple500,
              color: theme.palette.neutral.white,
            }}
          >
            Refund
          </Button>
        );
    }
  };

  const onlineColumn = useMemo(() => [
    {
      accessorKey: "booking_date",
      header: "Date",
      cell: ({ getValue }) => {
        const cellValue = getValue().toString();
        return moment(cellValue).format("DD-MM-yyyy");
      },
    },
    { accessorKey: "customer_name", header: "Customer Name" },
    { accessorKey: "customer_mobile_number", header: "Customer No." },
    { accessorKey: "product_title", header: "Product" },
    { accessorKey: "total_seat", header: "Person" },
    {
      accessorKey: "start_date",
      header: "Trip Date",
      cell: ({ getValue }) => {
        const cellValue = getValue().toString();
        return moment(cellValue).format("DD-MM-yyyy");
      },
    },
    { accessorKey: "deposit_amount", header: "Deposit" },
    { accessorKey: "total_amount", header: "Total" },
    { accessorKey: "pending_amount", header: "Pending Amount" },
  ]);

  // Tanstack Columns
  const tanColumn = useMemo(() => [
    { accessorKey: "staff_name", header: "Staff Name" },
    {
      accessorKey: "booking_date",
      header: "Date",
      cell: ({ getValue }) => {
        const cellValue = getValue().toString();
        return moment(cellValue).format("DD-MM-yyyy");
      },
      width: "150",
    },
    {
      accessorKey: "customer_name",
      header: "Customer",
    },
    { accessorKey: "customer_mobile_number", header: "Customer No." },
    { accessorKey: "product_name", header: "Product Name" },
    { accessorKey: "total_seat", header: "Person" },
    { accessorKey: "total_amount", header: "Total ₹" },
    { accessorKey: "pending_amount", header: "Pending ₹" },
    {
      accessorKey: "start_date",
      header: "Start Date",
      cell: ({ getValue }) => {
        const cellValue = getValue();
        return moment(cellValue).format("DD-MM-yyyy");
      },
    },
    {
      accessorKey: "booking_status",
      header: "Status",
      cell: ({ getValue }) => {
        return renderStatus(getValue());
      },
    },
    {
      accessorKey: "id",
      header: "Action",
      cell: ({ row }) => {
        const id = row.original.id;
        let bookingInfo = manualBookings.filter((book) => book.id === id);

        return (
          <Box sx={{ display: "flex", gap: "0.5rem" }}>
            <Button
              size="small"
              variant="contained"
              sx={{ backgroundColor: theme.palette.secondary.main }}
            >
              <Link to="/create-booking" state={{ booking: bookingInfo[0] }}>
                <AiOutlineEdit
                  fontSize="20"
                  color={theme.palette.secondary.purple500}
                />
              </Link>
            </Button>
            <Link
              to={"#"}
              // download={params.row.invoice}
            >
              <Button
                size="small"
                variant="contained"
                sx={{ backgroundColor: theme.palette.secondary.main }}
              >
                <BsDownload
                  fontSize="20"
                  color={theme.palette.secondary.purple500}
                />
              </Button>
            </Link>
            {client.role == 0 ? (
              <Button
                aria-label="delete"
                size="small"
                variant="contained"
                sx={{ backgroundColor: theme.palette.secondary.main }}
              >
                <AiOutlineDelete
                  onClick={(e) => {
                    alert("Do you really want to delete?");
                    deleteBooking(id);
                  }}
                  fontSize="20"
                  color={theme.palette.secondary.purple500}
                />
              </Button>
            ) : null}
          </Box>
        );
      },
    },
  ]);

  const filteredData = useMemo(
    () => filterDataByDateRange(manualBookings, searchParams),
    [manualBookings, searchParams]
  );

  return (
    <Helmet title="Bookings">
      <Wrapper>
        <Header
          title="Bookings"
          buttonText="Create Booking"
          onClick={() => navigate("/create-booking")}
        />
        {/* Search Box */}
        <Box pt="1rem">
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <TextField
                id="outlined-basic"
                label="Search booking by name, number etc"
                variant="outlined"
                fullWidth
                value={searchParams.query}
                onChange={(e) =>
                  setSearchParams({ ...searchParams, query: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                type="date"
                label="From Date"
                fullWidth
                value={searchParams.startDate}
                onChange={(e) =>
                  setSearchParams({
                    ...searchParams,
                    startDate: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                type="date"
                label="To Date"
                fullWidth
                value={searchParams.endDate}
                onChange={(e) =>
                  setSearchParams({ ...searchParams, endDate: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                select
                variant="outlined"
                fullWidth
                label="Select Staff"
                value={searchParams.staff}
                onChange={(e) =>
                  setSearchParams({ ...searchParams, staff: e.target.value })
                }
              >
                <MenuItem>Select Staff</MenuItem>
                {staffList?.map((item) => (
                  <MenuItem value={item.name} key={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            margin: "1rem 0",

            "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
              color: theme.palette.secondary.purple500,
              background: theme.palette.secondary.main,
            },
          }}
        >
          <Tabs
            value={value}
            selectionFollowsFocus
            onChange={handleChange}
            centered={isMobile ? true : false}
            variant={isMobile ? "fullWidth" : "standard"}
            TabIndicatorProps={{
              style: { background: theme.palette.secondary.purple500 },
            }}
          >
            <Tab
              label="Online Booking"
              {...a11yProps(0)}
              sx={{
                boxShadow: "0px 3px 2px 0px rgba(0,0,0,0.4)",
                fontWeight: "600",
                color: theme.palette.neutral.grey900,
                backgroundColor: theme.palette.neutral.grey200,
              }}
            />
            <Tab
              label="Manual Booking"
              {...a11yProps(1)}
              sx={{
                boxShadow: "0px 3px 2px 0px rgba(0,0,0,0.4)",
                fontWeight: "600",
                color: theme.palette.neutral.grey900,
                backgroundColor: theme.palette.neutral.grey200,
              }}
            />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
          <Box height="100%">
            {isOBLoading ? (
              "Loading..."
            ) : (
              <TanstackTable columns={onlineColumn} data={onlineBooking} />
            )}
          </Box>
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          <Box height="80vh">
            {isMBLoading ? (
              "Loading..."
            ) : (
              <TanstackTable columns={tanColumn} data={filteredData} />
            )}
          </Box>
        </CustomTabPanel>

        {/* <div className="spinner">
          <Loader
            loaded={isMBLoading ? false : true}
            lines={20}
            length={15}
            width={5}
            radius={20}
            corners={1}
            rotate={0}
            direction={1}
            color={theme.palette.primary.main}
            speed={1}
            trail={60}
            shadow={false}
            hwaccel={false}
            className="spinner"
            zIndex={2e9}
            top="50%"
            left="50%"
            scale={1.0}
            loadedClassName="loadedContent"
          />
        </div> */}
      </Wrapper>
    </Helmet>
  );
};

export default Bookings;

{
  /* ; */
}
